<template>
  <b-container fluid >
    <loading :active.sync="isWorking" :is-full-page="true"></loading>
    <b-row align="center" align-h="center">
      <b-col><h4 style="color:white;">Inciar sesión</h4></b-col>
    </b-row>
    <b-row align-h="center">
      <b-col xl="3" lg="4" md="6" sm="10" cols="11" class="border-login">
        <b-row align-h="center" class="text-center title-login">
          <b-col><b-icon style="color:gray;" font-scale="7" icon="person-circle"/></b-col>
          <b-col cols="12" md="11">
            <b-alert :show="msjErrorLogin.time" dismissible variant="danger" @dismissed="msjErrorLogin.time=0" @dismiss-count-down="countDown">{{msjErrorLogin.msj}}</b-alert>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col cols="12" md="11">
            <b-form-group label="Usuario(CURP):">
              <b-form-input :state="!$v.inTxtUser.$invalid" type="text" v-model.trim="$v.inTxtUser.$model"></b-form-input>
              <div class="error" v-if="!$v.inTxtUser.minLength">El Curp es demasiado corto</div>
              <div class="error" v-if="!$v.inTxtUser.maxLength">El Curp es demasiado largo</div>
              <div class="error" v-if="!$v.inTxtUser.required">Este campo es obligatorio</div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col cols="12" md="11">
              <b-form-group label="Contraseña:">
                <b-form-input :state="!$v.inPassUser.$invalid" type="password" v-model.trim="$v.inPassUser.$model"></b-form-input>
                <div class="error" v-if="!$v.inPassUser.maxLength">La Contraseña es demasiado larga</div>
                <div class="error" v-if="!$v.inPassUser.required">Este campo es obligatorio</div>
                <b-link>
                  <span @click="showModalRecuperarContra">Recuperar Contraseña</span>
                </b-link>
              </b-form-group>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col cols="12" md="11">
            <vue-recaptcha ref="recaptchalogin" sitekey="6LdD09sUAAAAANCvTN2xMZqvfMOvzkdkOB1Zcpok" @verify="captchaLoginFilled" @expired="captchaLoginExpired" :loadRecaptchaScript="true" />
            <div class="error" v-if="msgErrorCaptcha!=''">{{msgErrorCaptcha}}</div><br>
          </b-col>
        </b-row>
        <b-row align-h="center" class="text-center">
          <b-col cols="12" md="11">
            <b-button pill block size="lg" class="btn-back-purple" :style="{background: gradientColor}" @click="login">Iniciar sesión <b-icon icon="arrow-right-circle"/></b-button>
          </b-col>
        </b-row><br>
        <b-row align-h="center" class="text-center margin-row">
          <b-col>
            <h6>¿No tienes cuenta? Registrate <a href="#" @click="showPanelLogup">aquí</a></h6>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- modals -->
    <b-modal v-model="isShowModalRecuperarContra" title="Recuperar Contraseña" hide-footer @hidden="cleanFormRecPass()">
        <b-form-group label="Ingrese su CURP:">
          <b-form-input :state="!$v.modeloRecuperarContraseña.username.$invalid" v-model.trim="$v.modeloRecuperarContraseña.username.$model"></b-form-input>
          <div class="error" v-if="!$v.modeloRecuperarContraseña.username.minLength">El CURP es demasiado corto</div>
          <div class="error" v-if="!$v.modeloRecuperarContraseña.username.maxLength">El CURP es demasiado largo</div>
          <div class="error" v-if="!$v.modeloRecuperarContraseña.username.required">Este campo es obligatorio</div>
        </b-form-group>
        <b-form-group label="Ingrese su correo electrónico:">
          <b-form-input :state="!$v.modeloRecuperarContraseña.correo.$invalid" v-model.trim="$v.modeloRecuperarContraseña.correo.$model"></b-form-input>
          <div class="error" v-if="!$v.modeloRecuperarContraseña.correo.email">El correo electrónico es incorrecto</div>
          <div class="error" v-if="!$v.modeloRecuperarContraseña.correo.required">Este campo es obligatorio</div>
        </b-form-group>
        <vue-recaptcha ref="recaptchaRecPass" sitekey="6LdD09sUAAAAANCvTN2xMZqvfMOvzkdkOB1Zcpok" @verify="captchaRecuPassFilled" @expired="captchaRecuPassExpired" :loadRecaptchaScript="true"/>
        <div class="error" v-if="msgErrorCaptchaPass!=''">{{msgErrorCaptchaPass}}</div><br>
        <b-button block size="lg" variant="primary" @click="sendRestablecerContraseña()">Restablecer contraseña</b-button>
    </b-modal>
  </b-container>
</template>

<script>
  import { mapState} from 'vuex';
  import { minLength, maxLength, required, email } from 'vuelidate/lib/validators';
  import { loginUser, recuperarContraseñaUser } from '@/axios-tools';
  import VueRecaptcha from 'vue-recaptcha';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import Vue from 'vue';
  import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
  Vue.use(BootstrapVue)
  Vue.use(BootstrapVueIcons)

  export default {
    computed: {
      ...mapState({
        gradientColor: state => state.user.gradientColor,
      }),
      idServiceStart: {
        get: function() {
          return this.$store.state.user.currentService;
        },
        set: function(newIdServiceStart) {
          this.$store.commit("updateCurrentService", newIdServiceStart);
        }
      },
      isWorking: {
        get: function() {
          return this.$store.state.user.isWorking;
        },
        set: function(newIsWorking) {
          this.$store.commit("updateIsWorking", newIsWorking);
        }
      },
      msjErrorLogin: {
        get: function() {
          return this.$store.state.user.msjErrorLogin;
        },
        set: function(newMsjErrorLogin) {
          this.$store.commit("updateMsjErrorLogin", {msj:newMsjErrorLogin.msj, time:newMsjErrorLogin.time});
        }
      }
    },
    components: {
      Loading,
      VueRecaptcha
    },
    data() {
      return {
        inTxtUser: "",
        inPassUser: "",
        msgErrorCaptcha:'',
        msgErrorCaptchaPass:'',
        isShowModalRecuperarContra: false,
        captchaLogin: null,
        captchaRecuPass: null,
        modeloRecuperarContraseña:{
          username:"",
          correo:""
        }
      }
    },
    methods: {
      countDown(dismissCountDown){
        this.$store.commit("updateTimeMsjErrorLogin", dismissCountDown);
      },
      sendRestablecerContraseña(){
        if(this.captchaRecuPass != null && this.captchaRecuPass != ''){
          recuperarContraseñaUser(this.modeloRecuperarContraseña.username, this.modeloRecuperarContraseña.correo, this.$route.params.municipio, this.$store, this.captchaRecuPass);
          this.cleanFormRecPass();
          this.$refs.recaptchaRecPass.reset();
          this.captchaRecuPass = null;
        }else{
          this.msgErrorCaptchaPass="Por favor complete el captcha";
        }
        this.isShowModalRecuperarContra = false;
      },
      cleanFormRecPass(){
        this.modeloRecuperarContraseña.username="";
        this.modeloRecuperarContraseña.correo="";
      },
      captchaRecuPassFilled(token){
        this.captchaRecuPass = token;
        this.msgErrorCaptchaPass='';
      },
      captchaRecuPassExpired(){
        this.captchaRecuPass = null;
      },
      captchaLoginFilled(token){/* Método que es llamado cuando el captcha es resuelto correctamente */
        this.captchaLogin = token;
        this.msgErrorCaptcha='';
      },
      captchaLoginExpired(){/* Método que es llamado cuando el token(captcha) ha expirado */
        this.captchaLogin = null;
      },
      showPanelLogup(){/* Método que redirige a la vista logup(user) */
        this.$store.commit("updateIsShowNewUser", true);
        this.$router.push('/' + this.$route.params.municipio + '/logupusuario');
        this.updatePathname();
      },
      showCatalogoTramites(){/* Método que redirige a la vista que contiene el catálogo de tramites */
        this.$router.push('/' + this.$route.params.municipio + '/tramitesuser');
        this.updatePathname();
      },
      login(){/* Método que envia la petición al servidor para dar acceso a los usuarios */
        if(!this.$v.inTxtUser.$invalid && !this.$v.inPassUser.$invalid){
          if(this.captchaLogin!=null){
            loginUser(this.$store, this.$router, this.inTxtUser, this.inPassUser, this.$route.params.municipio, this.captchaLogin);
            this.inTxtUser='';
            this.inPassUser='';
            this.$refs.recaptchalogin.reset();
            this.captchaLogin=null;
          }else{
            this.msgErrorCaptcha="Por favor complete el captcha";
          }
        }
      },
      showModalRecuperarContra(){/* Método que lanza el modal "Instrucciones para recuperar contraseña" */
        this.isShowModalRecuperarContra = true;
      },
      updatePathname(){/* Método que actualiza la variable 'pathname' en el store, para renderizar nuevamente los elementos de la barra de navegación */
        setTimeout(()=>{ this.$store.commit("updatePathname", location.pathname.split("/")[2]); }, 100);
      }
    },
    validations: {
        inTxtUser:{
            required,
            minLength: minLength(18),
            maxLength: maxLength(18)
        },
        inPassUser:{
            required,
            maxLength: maxLength(10)
        },
        modeloRecuperarContraseña:{
          username:{
            required,
            minLength: minLength(18),
            maxLength: maxLength(18)
          },
          correo:{
            email,
            required
          }
        }
    }
  }
</script>
<style>
.title-login{
  margin-top:20px;
}
div.error{
  color: red;
}
.margin-row{
  margin-bottom:20px;
}

</style>